import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import cls from "./index.module.scss";
import { Typography } from "@material-ui/core";
// import CheckIcon from '@material-ui/icons/Check'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    "@media(max-width:576px)": {
      top: 12,
    },
  },
  active: {
    "& $line": {
      background: "rgba(34, 195, 72, 1)",
    },
  },
  completed: {
    "& $line": {
      background: "rgba(34, 195, 72, 1)",
    },
  },
  error: {
    "& $line": {
      background: "red !important",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "rgb(204 204 204)",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 0px 4px rgb(255 255 255) inset",

    "@media(max-width:576px)": {
      width: 30,
      height: 30,
    },
  },

  active: {
    background: "#3569B2",
    boxShadow: "0px 0px 0px 4px rgb(255 255 255) inset",
  },
  completed: {
    background: "rgba(34, 195, 72, 1)",
  },
  error: {
    background: "red",
  },
});

function ColorlibStepIcon(props, bool) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  // console.log('PROPS', props)
  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]:
          props.icon === 1 && !bool.first
            ? false
            : props.icon === 3 && !bool.third
            ? false
            : props.icon === 4 && !bool.four
            ? false
            : completed,
        [classes.error]:
          props.icon === 1 && !bool.first
            ? true
            : props.icon === 3 && !bool.third
            ? true
            : props.icon === 4 && !bool.four
            ? true
            : false,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["", "", "", "", ""];
}
function getSliceSteps() {
  return ["", "", "", ""];
}

export default function Steps({
  activeStep,
  setActiveStep,
  params,
  checkFirstStep,
  next,
}) {
  const classes = useStyles();
  const steps = getSteps();
  const sliceSteps = getSliceSteps();
  const currentStep = next ? sliceSteps : steps;

  const listName = [
    {
      name: "Общие сведения",
      count: 0,
    },
    {
      name: "Детали",
      count: 1,
    },
    {
      name: "Соц.-дем. характеристики",
      count: 2,
    },
    {
      name: "Календарь",
      count: 3,
    },
    {
      name: "Скринер",
      count: 4,
    },
  ];
  const sliceListName = [
    {
      name: "Общие сведения",
      count: 0,
    },
    {
      name: "Детали",
      count: 1,
    },
    {
      name: "Соц.-дем. характеристики",
      count: 2,
    },
    {
      name: "Вопросы",
      count: 4,
    },
  ];
  return (
    <div className={cls.step}>
      <div id="stepper" className="profile">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          style={{ cursor: "pointer" }}
          connector={<ColorlibConnector />}
        >
          {currentStep.map((label, ind) => (
            <Step
              onClick={() => {
                params.activestep && setActiveStep(ind);
              }}
              className={`${activeStep === ind ? "border" : ""} ${cls.flex}`}
              key={label}
            >
              <div className={cls.name}>
                <Typography
                  className={`${cls.text} ${ind === activeStep && cls.active}`}
                >
                  {" "}
                  {next ? sliceListName[ind]?.name : listName[ind]?.name}
                </Typography>
              </div>
              <StepLabel
                className={cls.label}
                StepIconComponent={(e) => ColorlibStepIcon(e, checkFirstStep)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
}
