import axios from "axios";
import config from "../config/defaultSettings";
import Auth from '../services/auth'
// axios new instance
// const history = useHistory();
const request = axios.create({
  baseURL: `${config.baseURL}`,
  timeout: 8000,
});

// Error on connection
const errorHandler = (error, hooks) => {
  const refresh_token = localStorage.getItem('refresh_token')
  const params = {
    refresh_token: refresh_token,
  }
  if (error.response) {
    if (error.response.status === 403) {
    }
    if (error.response.status === 401) {
      Auth.RefreshToken(params)
        .then((res) => {
          localStorage.setItem('token', res.data.access_token)
          localStorage.setItem('refresh_token', res.data.refresh_token)
          window.location.reload('/home/dashboard')
        })
        .catch((err) => {
          window.location.replace('/auth/login')
          localStorage.removeItem('token')
        })
    }
  }
  return Promise.reject(error.response)
}

// request interceptor
request.interceptors.request.use((config) => {
  // here you can set tokens, client-ids, and other header types

  const token = localStorage.getItem('token')
  config.headers["platform-id"] = '7d4a4c38-dd84-4902-b744-0488b80a4c01'
  if (token) {
    config.headers['Authorization'] = `Bearer ` + token
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => response.data, errorHandler);

export default request;
