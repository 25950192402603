// import { ReactComponent as InfoIcon } from '../../assets/icons/infoIcon.svg'
// import { ReactComponent as CancelIcon } from '../../assets/icons/cancelIcon.svg'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import axios from '../../utils/axios'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { authActionTypes } from '../../redux/actions/authActions/authActionTypes'
import { toast } from 'react-toastify'

function Popup(props) {
  const {
    top,
    bottom,
    left,
    right,
    mode = 'light',
    children,
    title,
    isOpen,
    minWidth = 250,
    onClose,
    className,
    t,
    ...rest
  } = props
  const dispatch = useDispatch()
  // const listItems = children.map((el, idx) => (
  //   <p key={idx}>
  //     <button className="py-1 px-3">
  //       <a href={el.url}>{el.name}</a>
  //     </button>
  //   </p>
  // ));

  const popupStyle = `top-${top} bottom-${bottom} right-${right} left-${left}`

  const logOut = () => {
    axios
      .post(`/auth/logout`)
      .then((res) => {
        localStorage.removeItem('token')
        dispatch({
          type: authActionTypes.AUTH_LOGIN,
          payload: {
            userLogin: '',
            phoneNumber: '',
            accessToken: '',
            refreshToken: '',
            permissions: '',
          },
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error(t(`${err.data.error}`), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        })
      })
  }

  return (
    isOpen && (
      <>
        {/* <div
          onClick={onClose}
          className='fixed inset-0 z-40'
        ></div> */}
        <div
          {...rest}
          className={`${className} flex`}
          style={{ position: 'relative', cursor: 'pointer', zIndex: '99' }}
          onClick={() => logOut()}
        >
          <div
            {...rest}
            className={`${className} ${popupStyle} flex absolute z-50`}
            style={minWidth && { minWidth: minWidth }}
          >
            <div
              className='flex flex-row ease-in-out p-3 cursor-pointer'
              style={{
                background: mode === 'dark' ? '#333' : '#fff',
                borderRadius: '6px',
                boxShadow:
                  '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)',
              }}
            >
              <div className='mx-1 my-1'>
                <ExitToAppIcon style={{ color: '#0067f4' }} />
              </div>
              <div
                className={`${
                  mode === 'dark' && 'text-gray-200'
                } flex flex-col text-sm font-semibold mx-2 my-1 flex items-center`}
              >
                <h5>{title}</h5>
              </div>
              {/* <div onClick={onClose} className='absoulute inset-1'>
                <a>
                  <CloseIcon />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default Popup
