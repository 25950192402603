export default function Card({
  size = 'medium',
  title,
  children,
  extra,
  className,
  ...args
}) {
  return (
    <div className={`${className} rounded-lg bg-white`} {...args}>
      {title || extra ? (
        <div className='px-5 py-4 border-b flex justify-between items-center'>
          <div className='text-lg tracking-wide text-gray-800 font-semibold'>
            {title}
          </div>
          <div>{extra}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='p-5'>{children}</div>
    </div>
  )
}
