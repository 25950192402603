import React from 'react'
import cls from './index.module.scss'
import { FileIcon } from '../svg/FileIcon'
import { BackArrow } from '../svg/BackArrow'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { AddIcon } from '../svg/AddIcon'
import Button from '@material-ui/core/Button'

export default function TopPanel({ username, id, value }) {
  const history = useHistory()
  const data = [
    {
      name: 'Команда',
      icon: <FileIcon color={'#D5DADD'} />,
      active: false,
      href: `/home/dashboard`,
    },
    {
      name: `${username ? username : 'Добавить'}`,
      icon: <FileIcon color={'#4094F7'} />,
      active: true,
    },
  ]
  return (
    <div className={cls.panel}>
      <div className={cls.rowPanel}>
        <a
          onClick={() => {
            history.goBack()
          }}
        >
          <BackArrow />
        </a>
        {data.map((el, ind) => (
          <a
            key={ind}
            onClick={() => {
              el.href && history.push(`${el.href}`)
            }}
          >
            <div className={`${cls.path} ${el.active && cls.active}`}>
              <div className={cls.icon}>
                <FileIcon />
              </div>
              <Typography className={cls.title}>{el.name}</Typography>
            </div>
          </a>
        ))}
      </div>
      {id && value === 1 && (
        <div className={cls.btnGroup}>
          <Button
            onClick={() => {
              history.push(`/home/company/companyinquirycreate/${id}`)
            }}
            className={cls.btn}
          >
            <AddIcon /> <span>Добавить</span>
          </Button>
        </div>
      )}
    </div>
  )
}
