import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import cls from './index.module.scss'
import TopPanel from './TopPanel'
import * as Yup from 'yup'
import { Formik } from 'formik'
import InputMask from 'react-input-mask'
import Select from '../Select/index'
import Switch from '../Switch/index'
import Form from '../Form/index'
import validateForm from '../../functions/validateForm'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../utils/axios'
// tab panel
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Button from '../Buttons/index'
import TableResearcher from './TableResearcher'
import Researcher from '../../models/researchers'
import { toast } from 'react-toastify'
import Calendar from '../ClientInfo/clientCalendar'
import Admins from '../../models/permissions'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function EditResearcher() {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()
  //  tab panel
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  //   validation
  const ValidationSchema = Yup.object().shape({
    email: validateForm('email', t),
    name: validateForm('name', t),
    profession_title: validateForm('position', t),
    // phone: validateForm('phone_number', t),
  })
  //    initialState
  const [initialValue, setInitialValue] = useState({
    company_id: id,
    profession_title: '',
    role_id: '',
    email: '',
    name: '',
    phone: '',
    active: 0,
  })
  const [initialBoolean, setInitialBoolean] = useState(false)
  const [userName, setUserName] = useState()
  const [roll, setRoll] = useState({})

  const getResearcherRoll = () => {
    const param = {
      client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c03',
    }
    Admins.getAllRolls(param)
      .then((res) => {
        setRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ])
      })
      .catch((err) => console.log(err))
  }

  const getResearchers = () => {
    axios.get(`/researcher/${id}`).then((res) => {
      setInitialValue((old) => ({
        ...old,
        email: res.data.email,
        name: res.data.name,
        phone: res.data.phone,
        photo: res.data.photo,
        profession_title: res.data.profession_title,
        role_id: res.data.role_id,
        active: res.data.active,
      }))
      setUserName(res.data.name)
      setInitialBoolean(true)
    })
  }
  const onSubmit = (data) => {
    const sendData = {
      email: data.email.toLowerCase(),
      name: data.name,
      phone: data.phone,
      profession_title: data.profession_title,
      role_id: data.role_id,
      active: data.active,

    }
    Researcher.researcherUpdate(id, sendData)
      .then((res) => {
        console.log(res)
        getResearchers()
        history.goBack()
        toast.error(t(`Успешно!`), {
          className: 'bg-green-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        })
      })

      .catch((err) => {
        toast.error(t(`${err.data.message}`), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        })
      })
  }
  useEffect(() => {
    getResearchers()
  }, [id])
  useEffect(() => {
    getResearcherRoll()
  }, [])
  return (
    <div className={cls.root}>
      <div className={cls.top}>
        <TopPanel value={value} id={id} username={userName} />
      </div>
      <div className={cls.header} id='app_bar'>
        <AppBar className={cls.appbar} position='static'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
          >
            <Tab
              label='Общие сведения'
              style={{
                color: `${value === 0 && '#4094f7'}`,
                textTransform: 'none',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '24px',
              }}
              {...a11yProps(0)}
            />
            <Tab
              label='Запросы'
              style={{
                color: `${value === 1 && '#4094f7'}`,
                textTransform: 'none',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '24px',
              }}
              {...a11yProps(1)}
            />
            <Tab
              label='Календарь'
              style={{
                color: `${value === 1 && '#4094f7'}`,
                textTransform: 'none',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '24px',
              }}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>

        {initialBoolean && (
          <TabPanel value={value} index={0}>
            <div className={cls.row}>
              <div className={cls.card}>
                <Formik
                  initialValues={initialValue}
                  onSubmit={onSubmit}
                  validationSchema={ValidationSchema}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <div>
                        <div className={cls.cardTitle}>
                          <h1
                            className='text-black'
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '18px',
                              lineHeight: '24px',
                              color: 'rgba(26, 32, 36, 1)',
                            }}
                          >
                            {'Общие сведения'}
                          </h1>
                        </div>
                        <div className={cls.cardBody}>
                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('Ф.И.О')}</div>
                            <div className='w-2/3'>
                              <Form.Item name='name' formik={formik}>
                                <input
                                  type='text'
                                  {...formik.getFieldProps('name')}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('Должность')}</div>
                            <div className='w-2/3'>
                              <Form.Item
                                name='profession_title'
                                formik={formik}
                              >
                                <input
                                  {...formik.getFieldProps('profession_title')}
                                ></input>
                              </Form.Item>
                            </div>
                          </div>
                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('Телефон')}</div>
                            <div className='w-2/3'>
                              <Form.Item name='phone' formik={formik}>
                                <InputMask
                                  id='inputmask'
                                  mask={`+9999999999999999`}
                                  maskChar={null}
                                  {...formik.getFieldProps('phone')}
                                />
                                {/* <Input ></Input> */}
                              </Form.Item>
                            </div>
                          </div>
                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('E-mail')}</div>
                            <div className='w-2/3'>
                              <Form.Item name='email' formik={formik}>
                                <input
                                  {...formik.getFieldProps('email')}
                                ></input>
                                {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                              </Form.Item>
                            </div>
                          </div>

                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('Название Роли')}</div>
                            <div className='w-2/3'>
                              <Form.Item name='role_id' formik={formik}>
                                <Select
                                  height={"42px"}
                                  options={roll}
                                  onChange={(e) => {
                                    setInitialValue((el) => ({
                                      ...el,
                                      role_id: e.id,
                                    }))
                                  }}
                                  defaultValue={
                                    roll?.length >= 1
                                      ? roll.find(
                                        (el) => el.id === initialValue.role_id
                                      )
                                      : {}
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {/* <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('password')}</div>
                            <div className='w-2/3'>
                              <Form.Item name='password' formik={formik}>
                                <input
                                  {...formik.getFieldProps('password')}
                                ></input>
                              </Form.Item>
                            </div>
                          </div> */}
                          <div className='w-full py-4 flex items-baseline'>
                            <div className='w-1/3'>{t('Заблокировать')}</div>
                            <div className='w-2/3'>
                              <Switch
                                defaultChecked={
                                  initialValue.active === 0 ? false : true
                                }
                                id='is_active'
                                color='primary'
                              // checked={formik.values.is_active}
                              // onChange={(val) => formik.setFieldValue("is_active", val)}
                              />
                              {/*<Form.Item name="ownername" formik={formik}>*/}
                              {/*    <Input {...formik.getFieldProps("ownername")}>*/}
                              {/*    </Input>*/}
                              {/*</Form.Item>*/}
                            </div>
                          </div>

                          <div className='w-full py-6'>
                            <Form.Item formik={formik}>
                              <div className='flex gap-2 float-right'>
                                <Button
                                  onClick={() => history.goBack()}
                                  style={{
                                    color: 'blue',
                                    border: '1px solid rgba(229, 233, 235, 1)',
                                    padding: '20px ,16px',
                                    background: 'white',
                                  }}
                                >
                                  Назад
                                </Button>
                                <Button type='submit'>Сохранить</Button>
                              </div>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </TabPanel>
        )}
        <TabPanel value={value} index={1}>
          <TableResearcher />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Calendar duration={30} researcher={true} id={id} />
        </TabPanel>
      </div>
    </div>
  )
}
