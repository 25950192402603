import request from '../utils/axios'
const base_Url = {
  researchers: '/inquiry',
}
const requests = {
  inquiryGetAllList: (id) =>
    request({
      url: `${base_Url.researchers}`,
      method: 'get',
      params: id,
    }),
  inquiryItemList: (id) =>
    request({
      url: `${base_Url.researchers}/${id}`,
      method: 'get',
    }),
  deleteInquiry: (id) =>
    request({
      url: `${base_Url.researchers}/${id}`,
      method: 'delete',
    }),
  createInquiry: (data, id) =>
    request({
      url: `${base_Url.researchers}/${id}`,
      method: 'post',
      data: data,
    }),
  updateInquiry: (data) =>
    request({
      url: `${base_Url.researchers}/${data.id}`,
      method: 'put',
      data: data,
    }),
  updateInquiryStatus: (data) =>
    request({
      url: `${base_Url.researchers}/update_status`,
      method: 'put',
      data: data,
    }),
  getInquiryRespondents: (params) =>
    request({
      url: `${base_Url.researchers}/respondents`,
      method: 'get',
      params,
    }),
}
export default requests
