export const AddIcon = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.0001 6.83329H6.83342V11C6.83342 11.4583 6.45842 11.8333 6.00008 11.8333C5.54175 11.8333 5.16675 11.4583 5.16675 11V6.83329H1.00008C0.541748 6.83329 0.166748 6.45829 0.166748 5.99996C0.166748 5.54163 0.541748 5.16663 1.00008 5.16663H5.16675V0.999959C5.16675 0.541626 5.54175 0.166626 6.00008 0.166626C6.45842 0.166626 6.83342 0.541626 6.83342 0.999959V5.16663H11.0001C11.4584 5.16663 11.8334 5.54163 11.8334 5.99996C11.8334 6.45829 11.4584 6.83329 11.0001 6.83329Z'
      fill='white'
    />
  </svg>
)
