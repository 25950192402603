import request from "../utils/axios";
import config from "../config/defaultSettings";

const base_Url = {
  company_inquiery_url: "/inquiry",
  calculate_cost: "/inquiry/calculate_cost",
  attribut_Url: "/attribute_value",
  project: "/project",
  globalVarebel: "/variables",
};
const CompanyInquiery = {
  CompanyInquieryCreate: (data) => {
    return request({
      url: `${base_Url.company_inquiery_url}`,
      method: "post",
      data: data,
    });
  },
  AttributValue: (parmas) =>
    request({
      url: `${base_Url.attribut_Url}`,
      method: "get",
      params: {
        requirement_attribute_id: parmas.requirement_attribute_id,
        value: parmas.value,
        limit: 50,
        page: 1,
      },
    }),
  deleteInquier: (id) =>
    request({
      url: `${base_Url.company_inquiery_url}/${id}`,
      method: "delete",
    }),
  createProject: (data) =>
    request({
      headers: {
        "client-id": "0506aacd-8eaa-42bf-b3d0-9b45298a66ce",
      },
      url: `${config.managerBaseURL + "v1"}/${base_Url.project}`,
      method: "post",
      data: data,
    }),
  createStroy: (data, id) => {
    return request({
      headers: {
        "client-id": "0506aacd-8eaa-42bf-b3d0-9b45298a66ce",
      },
      url: `${config.managerBaseURL + "v1"}/${
        base_Url.project
      }/${id}/${"start-new"}`,
      method: "post",
      data: data,
    });
  },
  globalVarebel: () => {
    return request({
      url: `${base_Url.globalVarebel}`,
      method: "get",
    });
  },
  getInquiry: (id) => {
    return request({
      url: `${base_Url.company_inquiery_url}/${id}`,
      method: "get",
    });
  },
  calculateCost: (
    partisipant_role,
    duration,
    number_of_partisipants,
    additional_cost,
    company_id,
    interview_type
  ) => {
    return request({
      url: `${base_Url.calculate_cost}?partisipant_role=${partisipant_role}&duration=${duration}&number_of_partisipants=${number_of_partisipants}&additional_cost=${additional_cost}&company_id=${company_id}&is_online=${interview_type}`,
      method: "get",
    });
  },
  InquieryCreate: (copyRight) => {
    return request({
      url: `${base_Url.company_inquiery_url}`,
      method: "post",
      data: copyRight,
    });
  },
};
export default CompanyInquiery;
