  import logo from "../assets/icons/logo_blue.png";

const defaultSetting = {
  baseURL: process.env.REACT_APP_RESEARCHER_API_BASE_URL || "https://test.api.researcher.urecruit.ru/v1/",
  baseAppURL: process.env.REACT_APP_URL || "https://test.api.admin.urecruit.ru/v1/",
  canvasBaseURL: process.env.REACT_APP_CANVAS_BASE_URL || "https://test.admin.canvas.urecruit.ru/",
  managerBaseURL: process.env.REACT_APP_MANAGER_BASE_URL || "https://test.api.manager.urecruit.ru/",
  researcherBaseURL: process.env.REACT_APP_RESEARCHER_BASE_URL || "https://test.researcher.urecruit.ru",
  baseAdminURL: process.env.REACT_APP_BASE_URL || "https://test.admin.urecruit.ru",
  mode: "default", // mode => default | light | semi-dark
  avatar: "",
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky sidebar
  project: {
    title: "Urecruit",
    logo: logo,
    createdBy: "Udevs company",
  },
};

document.title = defaultSetting.project.title;

export default defaultSetting;
