export const BackArrow = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.6666 5.33335H3.21995L6.47328 2.08002C6.73328 1.82002 6.73328 1.39335 6.47328 1.13335C6.21328 0.873355 5.79328 0.873355 5.53328 1.13335L1.13995 5.52669C0.879946 5.78669 0.879946 6.20669 1.13995 6.46669L5.53328 10.86C5.79328 11.12 6.21328 11.12 6.47328 10.86C6.73328 10.6 6.73328 10.18 6.47328 9.92002L3.21995 6.66669H10.6666C11.0333 6.66669 11.3333 6.36669 11.3333 6.00002C11.3333 5.63335 11.0333 5.33335 10.6666 5.33335Z'
      fill='#B0BABF'
    />
  </svg>
)
