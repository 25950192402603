import { DashboardIcon, CalendarIcon, ResearcherIcon } from '../svg/Sidebar'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';

const menu = [
  {
    title: 'Команда',
    path: '/home/dashboard',
    isActive: false,
    icon: <DashboardIcon />,
  },
  {
    title: 'Запросы',
    path: '/home/research',
    isActive: true,
    icon: <ResearcherIcon />,
  },
  {
    title: 'Календарь',
    path: '/home/calendar',
    isActive: false,
    icon: <EventOutlinedIcon style={{ color: 'rgba(110, 139, 183, 1)' }} />,
  },
  {
    title: 'Транзакции',
    path: '/home/transactions',
    isActive: false,
    icon: <MonetizationOnIcon style={{ color: 'rgba(110, 139, 183, 1)' }} />,
  },
  // {
  //   title: "Settings",
  //   path: "/home/settings",
  //   isActive: false,
  //   icon: (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M15.192 9.81666C15.2254 9.54999 15.2504 9.28332 15.2504 8.99999C15.2504 8.71666 15.2254 8.44999 15.192 8.18332L16.9504 6.80832C17.1087 6.68332 17.1504 6.45832 17.0504 6.27499L15.3837 3.39166C15.2837 3.20832 15.0587 3.14166 14.8754 3.20832L12.8004 4.04166C12.367 3.70832 11.9004 3.43332 11.392 3.22499L11.0754 1.01666C11.0504 0.816657 10.8754 0.666656 10.667 0.666656H7.33369C7.12535 0.666656 6.95035 0.816657 6.92535 1.01666L6.60869 3.22499C6.10035 3.43332 5.63369 3.71666 5.20035 4.04166L3.12535 3.20832C2.93369 3.13332 2.71702 3.20832 2.61702 3.39166L0.950354 6.27499C0.842021 6.45832 0.892021 6.68332 1.05035 6.80832L2.80869 8.18332C2.77535 8.44999 2.75035 8.72499 2.75035 8.99999C2.75035 9.27499 2.77535 9.54999 2.80869 9.81666L1.05035 11.1917C0.892021 11.3167 0.850354 11.5417 0.950354 11.725L2.61702 14.6083C2.71702 14.7917 2.94202 14.8583 3.12535 14.7917L5.20035 13.9583C5.63369 14.2917 6.10035 14.5667 6.60869 14.775L6.92535 16.9833C6.95035 17.1833 7.12535 17.3333 7.33369 17.3333H10.667C10.8754 17.3333 11.0504 17.1833 11.0754 16.9833L11.392 14.775C11.9004 14.5667 12.367 14.2833 12.8004 13.9583L14.8754 14.7917C15.067 14.8667 15.2837 14.7917 15.3837 14.6083L17.0504 11.725C17.1504 11.5417 17.1087 11.3167 16.9504 11.1917L15.192 9.81666ZM9.00035 11.9167C7.39202 11.9167 6.08369 10.6083 6.08369 8.99999C6.08369 7.39166 7.39202 6.08332 9.00035 6.08332C10.6087 6.08332 11.917 7.39166 11.917 8.99999C11.917 10.6083 10.6087 11.9167 9.00035 11.9167Z"
  //         fill="#6E8BB7"
  //       />
  //     </svg>
  //   ),
  // },
]

export default menu
