import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #red",
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 380,
  },
}));

export default function CopyRight({ t, Cached, onSubmit }) {
  return (
    <div>
      <div onClick={onSubmit}>
        <Cached className="bg-orange-100 rounded text-orange-600 p-1 my-1" />{" "}
        <span className="ml-2">{t("Копировать")}</span>
      </div>
    </div>
  );
}
